<template>
  <v-container class="contenedor-main">
    <v-row>
      <general-list
        :url="'/cead'"
        tipo="cead"
        :headers="headers"
        :items="ceads"
        :global="'ceads'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre cead']"
      ></general-list>

      <general-list
        :url="'/carreras'"
        tipo="carrera"
        :headers="headersC"
        :items="carreras"
        :global="'carreras'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre carrera']"
      ></general-list>

      <general-list
        :url="'/agentes'"
        tipo="agente"
        :headers="headersA"
        :items="agentes"
        :global="'agentes'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre agente']"
      ></general-list>

      <general-list
        :url="'/modosPago'"
        tipo="modo de pago"
        :headers="headersMP"
        :items="modosPago"
        :global="'modosPago'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre modo de pago']"
      ></general-list>

      <general-list
        :url="'/moodles'"
        tipo="moodle"
        :headers="headersMoodle"
        :items="moodles"
        :global="'moodles'"
        :editedItem="{ nombre: null, usuario: null }"
        :defaultItem="{ nombre: null, usuario: null }"
        :forBack="['nombre', 'usuario']"
        :titleNew="['Nombre de moodle', 'Usuario de moodle']"
      ></general-list>
      <general-list
        :url="'/mediosVenta'"
        tipo="medio de venta"
        :headers="headersmedioV"
        :items="mediosVenta"
        :global="'mediosVenta'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre medio de venta']"
      ></general-list>
      <general-list
        :url="'/tiposAlerta'"
        tipo="estado de usuario"
        :headers="headersEstadoU"
        :items="estadoU"
        :global="'estadoU'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre alerta']"
      ></general-list>
      <general-list
        :url="'/estadosPago'"
        tipo="estado de pago"
        :headers="headersEstadoP"
        :items="estadosPagos"
        :global="'estadosPagos'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre']"
      ></general-list>
      <general-list
        :url="'/tiposRespuesta'"
        tipo="respuesta"
        :headers="headersR"
        :items="respuesta"
        :global="'respuesta'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre de respuesta']"
      ></general-list>
      <general-list
        :url="'/lineas'"
        tipo="linea"
        :headers="headersL"
        :items="lineas"
        :global="'lineas'"
        :editedItem="{ nombre: null, telefono: null }"
        :defaultItem="{ nombre: null, telefono: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre', 'Teléfono']"
      ></general-list>
      <general-list
        :url="'/servidores'"
        tipo="servidores"
        :headers="headersS"
        :items="servidores"
        :global="'servidores'"
        :editedItem="{ country: null, nombre: null }"
        :defaultItem="{ nombre: null, country: null }"
        :forBack="['nombre', 'country']"
        :titleNew="['País', 'Servidores']"
      ></general-list>
      <general-list
        :url="'/listaNegra'"
        tipo="lista negra"
        :headers="headersListaN"
        :items="listaNegra"
        :global="'listaNegra'"
        :editedItem="{
          // id: null,
          usuario: null,
          documento: null,
          telefono: null,
          estado: null,
        }"
        :defaultItem="{
          usuario: null,
          documento: null,
          telefono: null,
          estado: null,
        }"
        :forBack="['usuario', 'documento', 'telefono', 'estado']"
        :titleNew="['Usuario lista negra', 'Cédula', 'Teléfono', 'Estado']"
      >
      </general-list>
      <!-- Gestiones tipo  -->
      <general-list
        :url="'/tipoGestion'"
        tipo="tipo gestiones"
        :headers="headersTipoG"
        :items="tipoG"
        :global="'tipoG'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre tipo de la gestión']"
      ></general-list>
      <!-- Gestiones estado -->
      <general-list
        :url="'/estadoGestion'"
        tipo="estado gestiones"
        :headers="headersEstadoG"
        :items="estadoG"
        :global="'estadoG'"
        :editedItem="{ nombre: null }"
        :defaultItem="{ nombre: null }"
        :forBack="['nombre']"
        :titleNew="['Nombre del estado de la gestión']"
      ></general-list>
      <!-- esto abajo es nuevo -->
      <edit-list
        :url="'/datosTrabajoVenta'"
        tipo="documentos requeridos trabajos"
        :headers="headersNt"
        :items="notasTrabajos"
        :global="'notasTrabajos'"
        :title="['Curso', 'Notas']"
      ></edit-list>
      <!-- moodle de trabajos -->
      <general-list
        :url="'/listaNegraTrabajos'"
        tipo="lista negra trabajos"
        :headers="headersMoodleT"
        :items="listaNegraTrabajos"
        :global="'listaNegraTrabajos'"
        :editedItem="{ telefono: null, estado: null }"
        :defaultItem="{ telefono: null, estado: null }"
        :forBack="['telefono', 'estado']"
        :titleNew="['Tel', 'estado']"
      ></general-list>
      <!--ips malas  -->
      <v-col class="noP" cols="12" md="3">
        <v-data-table
          :headers="headersIpsNO"
          :items="averiados"
          :search="searchIpsNO"
          sort-by="Nombre"
          class="elevation-1"
          :header-props="{ sortByText: 'Organizar por' }"
          no-results-text="Sin resultados"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
          }"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title>
                <v-text-field
                  v-model="searchIpsNO"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="revirirIP(item)"> mdi-check </v-icon>
          </template>
          <template v-slot:no-data>
            <v-progress-circular
              class="contenedor-tools__progress"
              :width="3"
              :size="20"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="12">
        <v-container>
          <v-row>
            <general-list-cargue-excel
              :archivos="'Cargar archivos para servidores o países (Nombres de columnas de Excel: Países y Servidores)'"
              :url="'/servidores'"
            ></general-list-cargue-excel>
            <general-list-cargue-excel
              :archivos="'Cargar archivos para moodles (Nombre de columnas de Excel: Moodles y Usuarios)'"
              :url="'/moodles'"
            ></general-list-cargue-excel>
            <general-list-cargue-excel
              :archivos="'Cargar archivos para lista negra (Nombre de columnas de Excel: Usuario, Cédula, Teléfono y Estado)'"
              :url="'/listaNegra'"
            ></general-list-cargue-excel>
            <general-list-cargue-excel
              :archivos="'Cargar archivos para lista negra de TRABAJOS (Nombre de columnas de Excel: Teléfono y Estado)'"
              :url="'/listaNegraTrabajos'"
            ></general-list-cargue-excel>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import GeneralListCargueExcel from "./component/GeneralListCargueExcel.vue";
// import GeneralList from "./component/GeneralList.vue";
import { mapState } from "vuex";
// import EditList from "./component/EditList.vue";
import data from "./Tools/data";

export default {
  components: {
    GeneralListCargueExcel: () =>
      import(
        /* webpackChunkName: "GeneralListCargueExcel"  */ "./component/GeneralListCargueExcel.vue"
      ),
    GeneralList: () =>
      import(
        /* webpackChunkName: "GeneralList"  */ "./component/GeneralList.vue"
      ),
    EditList: () =>
      import(/* webpackChunkName: "EditList"  */ "./component/EditList.vue"),
  },

  data() {
    return {
      nombre: null,
      searchIpsNO: "",
      // CEADS
      headers: [
        {
          text: "Ceads",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      // carreras
      headersC: [
        {
          text: "Carreras",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      // lineas
      headersL: [
        {
          text: "Lineas",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        {
          text: "Teléfono",
          align: "start",
          sortable: false,
          value: "telefono",
        },
        { text: "", value: "actions" },
      ],
      // Servidores
      headersS: [
        {
          text: "País",
          align: "start",
          sortable: false,
          value: "country",
        },
        {
          text: "Servidores",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      // Agentes
      headersA: [
        {
          text: "Agentes",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      //  metodos de pago
      headersMP: [
        {
          text: "Modos de pago",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      // moodle
      headersMoodle: [
        {
          text: "Moodles",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Usuario", value: "usuario" },
        { text: "", value: "actions" },
      ],
      // Medios de venta
      headersmedioV: [
        {
          text: "Medio de venta",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      //  Estados de u
      headersEstadoU: [
        {
          text: "Estado de usuario (alertas)",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      // servidos no ingresa
      headersIpsNO: [
        {
          text: "Servidor no ingresa",
          align: "start",
          sortable: false,
          value: "servidor",
        },
        {
          text: "País",
          align: "start",
          sortable: false,
          value: "pais",
        },
        { text: "", value: "actions" },
      ],
      // rtas
      headersR: [
        {
          text: "Respuesta",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      //  estado pgos
      headersEstadoP: [
        {
          text: "Estado de pagos",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "", value: "actions" },
      ],
      //  Lista negra
      headersListaN: [
        {
          text: "Lista negra",
          align: "start",
          sortable: false,
          value: "usuario",
        },
        { text: "Cédula", value: "documento" },
        { text: "Teléfono", value: "telefono" },
        { text: "Estado", value: "estado" },
        { text: "", value: "actions" },
      ],
      // notasTrabajs
      headersNt: [
        {
          text: "Curso",
          align: "start",
          sortable: false,
          value: "curso",
        },
        { text: "Notas", value: "notas" },
        { text: "", value: "actions" },
      ],
      // moodles para trabajos
      headersMoodleT: [
        {
          text: "Lista negra",
          align: "start",
          sortable: false,
          value: "telefono",
        },
        { text: "Estado", value: "estado" },
        { text: "", value: "actions" },
      ],
      // tipo gestiones
      headersTipoG: [
        {
          text: "Tipo gestión",
          align: "start",
          sortable: false,
          value: "tipo",
        },
        { text: "", value: "actions" },
      ],
      // estado gestiones
      headersEstadoG: [
        {
          text: "Estado gestión",
          align: "start",
          sortable: false,
          value: "tipo",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: mapState([
    "ceads",
    "carreras",
    "lineas",
    "servidores",
    "agentes",
    "modosPago",
    "moodles",
    "mediosVenta",
    "estadoU",
    "estadosPagos",
    "averiados",
    "respuesta",
    "listaNegra",
    "notasTrabajos",
    "listaNegraTrabajos",
    "tipoG",
    "estadoG",
    "IpsNO",
  ]),

  mounted() {
    document.title = "Listas";
    data.dataList("/cead", "ceads", "listar", "ceads");
    data.dataList("/carreras", "carreras", "listar", "carreras");
    data.dataList("/agentes", "lista", "listar", "agentes");
    data.dataList("/modosPago", "lista", "listar", "modosPago");
    data.dataList("/moodles", "lista", "listar", "moodles");
    data.dataList("/mediosVenta", "lista", "listar", "mediosVenta");
    data.dataList("/tiposAlerta", "lista", "listar", "estadoU");
    data.dataList("/estadosPago", "lista", "listar", "estadosPagos");
    data.dataList("/tiposRespuesta", "lista", "listar", "respuesta");
    data.dataList("/lineas", "lista", "listar", "lineas");
    data.dataList("/servidores", "lista", "listar", "servidores");
    data.dataList("/listaNegra", "lista", "listar", "listaNegra");
    data.dataList("/tipoGestion", "lista", "listar", "tipoG");
    data.dataList("/estadoGestion", "lista", "listar", "estadoG");
    data.dataList("/datosTrabajoVenta", "lista", "listar", "notasTrabajos");
    data.dataList("/servidores", "lista", "averiados", "averiados");
    data.dataList(
      "/listaNegraTrabajos",
      "lista",
      "listar",
      "listaNegraTrabajos"
    );
  },

  methods: {
    revirirIP(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "reparar");
          fd.append("country", data.pais);
          fd.append("servidor", data.servidor);
          axios.post(host + "/servidores", fd).then((result) => {
            let data = result.data;
            // console.log(JSON.stringify(data))
            if (data.text == "OK") {
              this.$strore.state.averiados.splice(this.editedIndexIpNO, 1);
              this.$strore.state.averiados.splice(this.editedIndexIpNO, 1);
              this.nombre = null;
              this.$alert("Servidor y país reparados");
            } else {
              this.$alert("No se pudo reparar");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.noP {
  padding: 5px 5px !important;
}
</style>